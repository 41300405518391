<template lang="pug">
BasePane(:savedStyle="false")
  Accordion(fix)
    OmButton#settings.mt-3(@click="openLuckyWheelSettings" primary small block) {{ $t('settings') }}
    template(v-for="{ label, prop } in labelAndProps")
      OmColorInput(
        :label="$t(label)"
        :property="prop"
        typeOfComponent="luckyWheel"
        layout="col"
        dsAllowed
      )
  hr.my-3
  Font(hideAlign)
    template(#colors)
      OmColorInput(
        :label="$t('color')"
        property="luckyWheel.labelTextColor"
        typeOfComponent="luckyWheel"
        layout="col"
        dsAllowed
      )
  template(#advancedContent)
    Dropdown#position(
      v-if="mobilePreview"
      :label="$t('position')"
      :items="mobilePositions"
      v-model="mobilePosition"
      layout="col"
    )
</template>
<script>
  import { mapMutations, mapState } from 'vuex';
  import itemMixin from '@/editor/mixins/item';
  import Accordion from '@/components/Editor/Accordion/Accordion.vue';
  import Font from '@/components/Editor/Blocks/Font.vue';
  import Dropdown from '@/components/Editor/Dropdown/Dropdown.vue';
  import BasePane from './BasePane.vue';

  export default {
    components: {
      Accordion,
      Font,
      Dropdown,
      BasePane,
    },
    mixins: [itemMixin],
    data() {
      return {
        mobilePositions: [
          { text: this.$t('center'), value: 'center' },
          { text: this.$t('bottom'), value: 'bottom' },
        ],
        labelAndProps: [
          { label: 'background', prop: 'luckyWheel.backgroundFillColor' },
          { label: 'sliceColor1', prop: 'luckyWheel.sliceColor1' },
          { label: 'sliceColor2', prop: 'luckyWheel.sliceColor2' },
          { label: 'sliceColor3', prop: 'luckyWheel.sliceColor3' },
          { label: 'sliceColor4', prop: 'luckyWheel.sliceColor4' },
          { label: 'dots', prop: 'luckyWheel.dots' },
          { label: 'sticks', prop: 'luckyWheel.sticks' },
          { label: 'radius', prop: 'luckyWheel.radius' },
          { label: 'ticker', prop: 'luckyWheel.ticker' },
        ],
      };
    },
    computed: {
      ...mapState(['mobilePreview']),
      mobilePosition: {
        get() {
          return this.getValueOf('mobile.position');
        },
        set(value) {
          this.setValueOf('mobile.position', value);
        },
      },
    },
    methods: {
      ...mapMutations(['changeFormManagerVisibility']),
      openLuckyWheelSettings() {
        this.changeFormManagerVisibility({ show: 'lumiraSettings' });
      },
    },
  };
</script>
